'use strict';

class SignupController {
  //start-non-standard
  user = {};
  errors = {};
  submitted = false;
  //end-non-standard

  constructor($scope, $state, $http, $location, Auth) {
    this.Auth = Auth;
    this.$state = $state;
    this.$scope = $scope;
    this.$scope.vars = {
      validity: false
    };

    /**************************************************************************/

    $http.get('/assets/data/codes.json').success(function() {
      // Keep until fixed!!!
      $scope.page = {
        title: 'Attivazione/Rinnovo',
        form: {
          creds: [[
            { id: 'user-name', label: 'Nome', type: 'text', value: '', listener: $scope.validateName, invalid: true, width: 6, tooltip: 'Inserire un nome valido' },
            { id: 'user-surname', label: 'Cognome', type: 'text', value: '', listener: $scope.validateName, invalid: true, width: 6, tooltip: 'Inserire un cognome valido' }
          ], [
            { id: 'user-email', label: 'Email', type: 'text', value: '', listener: $scope.validateEmail, invalid: true, width: 6, tooltip: 'Inserire un indirizzo email valido' },
            { id: 'user-email-confirm', label: 'Conferma email', type: 'text', value: '', listener: $scope.validateConfirmEmail, invalid: true, width: 6, tooltip: 'Inserire nuovamente lo stesso indirizzo email' }
          ], [
            { id: 'user-password', label: 'Password', type: 'password', value: '', listener: $scope.validatePassword, invalid: true, width: 6, tooltip: 'Inserire una password di almeno 8 caratteri e che contenga almeno una lettera minuscola, almeno una maiuscola e almeno un numero' },
            { id: 'user-password-confirm', label: 'Conferma password', type: 'password', value: '', listener: $scope.validateConfirmPassword, invalid: true, width: 6, tooltip: 'Inserire nuovamente la stessa password' }
          ], [
            { id: 'activation-code', label: 'Codice di attivazione', type: 'text', value: '', listener: $scope.validateKey, invalid: true, width: 12, tooltip: 'Inserire una chiave di attivazione valida' }
          ]],
          recreds: [[
            { id: 'user-email', label: 'Email', type: 'text', value: '', listener: $scope.renewalValidateField, invalid: true, width: 6, tooltip: 'Inserire l\'indirizzo email dell\'utente da rinnovare' },
            { id: 'user-password', label: 'Password', type: 'password', value: '', listener: $scope.renewalValidateField, invalid: true, width: 6, tooltip: 'Inserire la password dell\'utente da rinnovare' }
          ], [
            { id: 'renewal-code', label: 'Codice di rinnovo', type: 'text', value: '', listener: $scope.renewalValidateField, invalid: true, width: 12, tooltip: 'Inserire la chiave di attivazione valida' }
          ]]
        }
      };
    });

    $scope.validateName = field => {
      // field.invalid = !field.value.match(/^[A-Z][a-z]+$/);
      field.invalid = !field.value.length;
      $scope.validityCheck();
    };

    $scope.validateEmail = field => {
      field.invalid = !emailAddresses.parseOneAddress(field.value);
      $scope.validateConfirmEmail($scope.page.form.creds[1][1]);
      $scope.validityCheck();
    };

    $scope.validateConfirmEmail = field => {
      const email = $scope.page.form.creds[1][0].value;
      field.invalid = email !== field.value;
      $scope.validityCheck();
    };

    $scope.validateKey = field => {
      const matches = field.value.trim().match(/^[0-9a-f]{32}$/i);
      field.invalid = !(matches && matches.length === 1);
      $scope.validityCheck();
    };

    $scope.validatePassword = field => {
      const password = field.value;
      const length = password.length >= 8;
      const caps = password.match(/[A-Z]/g);
      const simples = password.match(/[a-z]/g);
      const nums = password.match(/[0-9]/g);
      field.invalid = !(length && caps && simples && nums);
      $scope.validateConfirmPassword($scope.page.form.creds[2][1]);
      $scope.validityCheck();
    };

    $scope.validateConfirmPassword = field => {
      const password = $scope.page.form.creds[2][0].value;
      field.invalid = password !== field.value;
      $scope.validityCheck();
    };

    $scope.validityCheck = () => {
      $scope.vars.validity =
        !$scope.page.form.creds[0][0].invalid &&
        !$scope.page.form.creds[0][1].invalid &&
        !$scope.page.form.creds[1][0].invalid &&
        !$scope.page.form.creds[1][1].invalid &&
        !$scope.page.form.creds[2][0].invalid &&
        !$scope.page.form.creds[2][1].invalid &&
        !$scope.page.form.creds[3][0].invalid;
    };

    $scope.submit = function() {
      if(!$scope.vars.validity) {return;}

      const name = $scope.page.form.creds[0][0].value;
      const surname = $scope.page.form.creds[0][1].value;
      const email = $scope.page.form.creds[1][0].value.toLowerCase();
      const password = $scope.page.form.creds[2][0].value;
      const key = $scope.page.form.creds[3][0].value.trim();
      const user = { name: name + ' ' + surname, email: email,
        password: password };

      $scope.activateKey(email, key, () => $scope.registerUser(user, key));
    };

    $scope.activateKey = (email, key, callback, callback2) => {
      const url = '/api/v1/key/activate/' + key + '/' + email;
      $http({ url: url, method: 'POST' }).then(() => {
        if(callback) {callback();}
        else {
          $scope.alertSuccess();
          $location.path('/'); // or $state.go('login');?
        }
      }).catch(err => {
        const appErrMsg = err.data.message;
        const userErrMsg = $scope.getUserErrMsg(appErrMsg);
        alert(userErrMsg);
        if(callback2) {callback2();}
      });
    };

    $scope.registerUser = (user, key) => {
      this.Auth.createUser(user).then(() => {
        $scope.alertSuccess();
        $location.path('/'); // or $state.go('login');?
      }).catch(err => {
        console.log(err);
        const url = '/api/v1/key/revert/' + key;
        $http({ url: url, method: 'POST' });
        alert('Impossibile attivare il codice per l\'email inserito');
      });
    };

    $scope.alertSuccess = () => alert('Attivazione avvenuta con successo');

    $scope.getUserErrMsg = (appErrMsg) => {
      const ERROR_KEY_NOT_FOUND = 'Key was not found in DB';
      const ERROR_KEY_ALREADY_ACTIVE = 'Key is already active';
      const ERROR_KEY_ALREADY_DEACTIVED = 'Key is already deactivated';
      const ERROR_KEY_HAS_EXPIRED = 'Key has expired';
      const userErrors = {
        generic: 'Errore durante l\'attivazione. Riprovare più tardi',
        notFound: 'Codice di attivazione non valido',
        alreadyActive: 'Il codice di attivazione è già attivo',
        alreadyDeactivated: 'Il codice di attivazione è disattivo',
        expired: 'Codice di attivazione scaduto'
      };

      switch(appErrMsg) {
        case ERROR_KEY_NOT_FOUND: return userErrors.notFound;
        case ERROR_KEY_ALREADY_ACTIVE: return userErrors.alreadyActive;
        case ERROR_KEY_ALREADY_DEACTIVED: return userErrors.alreadyDeactivated;
        case ERROR_KEY_HAS_EXPIRED: return userErrors.expired;
        default: return userErrors.generic;
      }
    };

    $scope.renewalValidateField = field => {
      field.invalid = !field.value.length;
      $scope.renewalValidityCheck();
    };

    $scope.renewalValidityCheck = () => {
      $scope.vars.renewalValidity =
        !$scope.page.form.recreds[0][0].invalid &&
        !$scope.page.form.recreds[0][1].invalid &&
        !$scope.page.form.recreds[1][0].invalid;
    };

    $scope.submitRenewal = () => {
      if(!$scope.vars.renewalValidity) {return;}

      const email = $scope.page.form.recreds[0][0].value.toLowerCase();
      const password = $scope.page.form.recreds[0][1].value;
      const key = $scope.page.form.recreds[1][0].value;
      const user = { email: email, password: password };

      $scope.loginUser(user, () => $scope.activateKey(email, key, null, () => {
        Auth.logout();
      }));
    };

    $scope.loginUser = (user, callback) => {
      this.Auth.login(user).then(() => {
        callback();
      }).catch(err => {
        const NO_VALID_KEY_FOUND = 'No valid key found for user';

        if(err.message === NO_VALID_KEY_FOUND) {
          callback();
        } else {
          alert('Email e/o password non corretti');
          // TODO: fix going back to login from here
        }
      });
    };
  }
}

angular.module('appApp')
  .controller('SignupController', SignupController);
